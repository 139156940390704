import React from 'react'
import "./services.css";
import { FaCheck } from "react-icons/fa6";

const Services = () => {
  return (
  <section id='services'>
    <h5>What I Offer</h5>
    <h2>Services</h2>

    <div className="container services__container">
      <article className='service'>
        <div className="service__head">
          <h3>UI/UX Design</h3>
        </div>

        <ul className='service__list'>
          <li>
            <FaCheck className='service__list-icon'/>
            <p>User Research.</p>
          </li>
          <li>
            <FaCheck className='service__list-icon'/>
            <p>Clickable, User flow, Interactive Prototyping.</p>
          </li>
          <li>
            <FaCheck className='service__list-icon'/>
            <p>Visual Design.</p>
          </li>
          <li>
            <FaCheck className='service__list-icon'/>
            <p>User Interface (UI) Design.</p>
          </li>
          <li>
            <FaCheck className='service__list-icon'/>
            <p>Web Design.</p>
          </li>
        </ul>
      </article>
       {/* {End OF UI/UX} */}

      <article className='service'>
        <div className="service__head">
          <h3>Web Development</h3>
        </div>

        <ul className='service__list'>
          <li>
            <FaCheck className='service__list-icon'/>
            <p>Full-Stack Development.</p>
          </li>
          <li>
            <FaCheck className='service__list-icon'/>
            <p>E-Commerce Development.</p>
          </li>
          <li>
            <FaCheck className='service__list-icon'/>
            <p>Website Maintenance.</p>
          </li>
          <li>
            <FaCheck className='service__list-icon'/>
            <p>SEO (Search Engine Optimization).</p>
          </li>
          <li>
            <FaCheck className='service__list-icon'/>
            <p>Web Hosting.</p>
          </li>
          <li>
            <FaCheck className='service__list-icon'/>
            <p>Responsive, Custom design.</p>
          </li>
        </ul>
      </article>
       {/* {End OF Web Development} */}

      <article className='service'>
        <div className="service__head">
          <h3>Python</h3>
        </div>

        <ul className='service__list'>
          <li>
            <FaCheck className='service__list-icon'/>
            <p>Web Development.</p>
          </li>
          <li>
            <FaCheck className='service__list-icon'/>
            <p>API Development.</p>
          </li>
          <li>
            <FaCheck className='service__list-icon'/>
            <p>UI/UX Design and Prototyping.</p>
          </li>
          <li>
            <FaCheck className='service__list-icon'/>
            <p>Code Review and Optimization.</p>
          </li>
          <li>
            <FaCheck className='service__list-icon'/>
            <p>build control, bug tracking, testing.</p>
          </li>
        </ul>
      </article>
       {/* {End OF UI/UX} */}

    </div>
  </section>
  )
}

export default Services;