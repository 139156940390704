import React from "react";
import "./about.css";
import ME from "../../assets/2.jpeg";
import { FaAward } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { VscFolderLibrary } from "react-icons/vsc";

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>2 Years Working</small>
            </article>

            <article className="about__card">
              <FaUsers className="about__icon" />
              <h5>Clients</h5>
              <small>10</small>
            </article>

            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>25+ Completed</small>
            </article>
          </div>
          <p>
            As a Junior Front-End Developer.I possess an Impressive arsenal of skills in HTML, CSS, JavaScript, React, Tailwind and Python. Iexcel in designing and maintaining responsive websites that offer a smooth user experience.My expertise lies in crafting dynamic, engaging interfaces through writing clean and optimized code and utilizing citting-edge development tools and techniques.I am also team player who thrives in collaborating with cross-function teams to produce outstanding web applications.
          </p>
          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
