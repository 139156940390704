import React from 'react'
import "./footer.css";
import { FaFacebookF } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { FaTwitter } from "react-icons/fa";

const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'>AnsILyas</a>
      <ul className='permalinks'>
        <li><a href="#"></a>Home</li>
        <li><a href="#about"></a>About</li>
        <li><a href="#expeirience"></a>Expeirience</li>
        <li><a href="#services"></a>Services</li>
        <li><a href="#portfolio"></a>Portfolio</li>
        <li><a href="#testimonials"></a>Testimonials</li>
        <li><a href="#contact"></a>Contact</li>
      </ul>

      <div className="footer__socials">
        <a href="https://facebook.com" target='blacnk'><FaFacebookF/></a>
        <a href="https://instagram.com" target='blank'><BsInstagram/></a>
        <a href="https://twitter.com" target='blank'><FaTwitter /></a>
      </div>

      <div className="footer__copyright">
        <small>Copyright © 2023 Ans ILyas • Web Designer & Developer</small>
      </div>
    </footer>
  )
}

export default Footer;
