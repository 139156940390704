import React from "react";
import "./testimonials.css";
import AVTR1 from "../../assets/avatar1.jpeg";
import AVTR2 from "../../assets/avatar2.jpeg";
import AVTR3 from "../../assets/avatar3.jpeg";
import AVTR4 from "../../assets/avatar4.jpg";

// import Swiper core and required modules
import {Pagination } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


const data = [
  {
    avatar: AVTR1,
    name: "Talha Ilyas",
    review:
      "Exceptional web developer! Their creativity, attention to detail, and timely delivery exceeded our expectations. Our website not only looks stunning but also functions seamlessly. Highly recommended!",
  },
  {
    avatar: AVTR2,
    name: "Muhamyman Ali",
    review:
      "Demonstrated proficiency in coding, problem-solving, and collaborative teamwork. Delivered high-quality solutions with efficiency and attention to detail.",
  },
  {
    avatar: AVTR3,
    name: "Ahmad Zuifiqar",
    review:
      "Transformed our vision into a user-friendly and visually stunning website. Responsive, creative, and highly skilled – a pleasure to work with.",
  },
  {
    avatar: AVTR4,
    name: "Kwame Deespite",
    review:
      "The React website delivered a seamless user experience with its responsive design and smooth navigation. The interactive components added a dynamic touch, enhancing overall engagement. Impressed with the modern feel and functionality.",
  },
];

const Testimonials = () => {
  return (
    <section id="testimonials">
      <h5>Review</h5>
      <h2>Testimonial</h2>

      <Swiper className="container testimonials__container"
      // install Swiper modules
      modules={[Pagination]}
      spaceBetween={40}
      slidesPerView={1}
      pagination={{ clickable: true }}
      >
        {data.map(({ avatar, name, review }, index) => {
          return (
            <SwiperSlide key={index} className="testimonial">
              <div className="client__avatar">
                <img src={avatar} alt="Avatar One" />
              </div>
              <h5 className="client__name">{name}</h5>
              <small className="client__review">{review}
              </small>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default Testimonials;
