import React from "react";
import "./portfolio.css";
import IMG2 from "../../assets/portfolio2.jpg";
import IMG3 from "../../assets/portfolio3.jpg";
import IMG6 from "../../assets/portfolio6.jpg";
import IMG1 from "../../assets/portfolio1.jpg";
import IMG4 from "../../assets/portfolio4.jpg";
import IMG5 from "../../assets/portfolio5.jpg";

const data = [
  {
    id: 1,
    image: IMG1,
    title: "Python Program of Cinema Simulator",
    github: "https://github.com/ansilyas/Python-Project_3 ",
    demo: "",
  },
  {
    id: 2,
    image: IMG2,
    title: "UI Template of Login Page",
    github: "https://github.com/ansilyas/HTML_Project__2 ",
    demo: "",
  },
  {
    id: 3,
    image: IMG3,
    title: " Static & Responsive Barber Shop website",
    github: "https://github.com/ansilyas/HTML_Project ",
    demo: "",
  },
  {
    id: 4,
    image: IMG4,
    title: "Investment Calculator",
    github: "https://github.com/ansilyas/React-JS-Calculator ",
    demo: "",
  },
  {
    id: 5,
    image: IMG5,
    title: "Tic Tac Game",
    github: "https://github.com/ansilyas/Python-Project_8 ",
    demo: "",
  },
  {
    id: 6,
    image: IMG6,
    title: "Small AI Program",
    github: "https://github.com/ansilyas/Python-Project_2 ",
    demo: "",
  },
];

const Portfolio = () => {
  return (
    <section id="portfolia">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">
        {data.map(({ id, image, title, github, demo }) => {
          return (
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                <a
                  href={github}
                  className="btn"
                  target="blank"
                >
                  Github
                </a>
                <a
                  href={demo}
                  className="btn btn-primary"
                  target="blank"
                >
                  Live Demo
                </a>
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
