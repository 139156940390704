import React from "react";
import "./header.css";
import CAT from "./CAT";
import ME from "../../assets/1.png";
import HeaderSocials from "./HeaderSocials";

const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h5>Hello I'm</h5>
        <h1>Ans ILyas</h1>
        <h5 className="text-light">Fullstack Developer</h5>
        <CAT />
        <HeaderSocials />
        <div className="me">
          <img src={ME} alt="me" />
        </div>
        <a href="#contact" className="Scroll__down">Scroll Down</a>
      </div>
    </header>
  );
};

export default Header;
